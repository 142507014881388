html,
.chakra-modal__overlay,
.chakra-modal__content-container {
  min-width: 500px;
  min-height: 100%;
}

.wysiwyg-container {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }

  a {
    text-decoration: underline;
  }

  ul {
    padding: 20px 0 20px 20px;
  }

  ul li>ul {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  z-index: 999999999999;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

#WEB3_CONNECT_MODAL_ID>div {
  z-index: 19999;
}

.mce-content-body {
  outline: 0 !important;
}

.tox-editor-container {
  margin-left: -.25rem !important;
}

.tox.tox-tinymce-inline .tox-editor-header {
  box-shadow: 0 0 2px black !important;
  border: none !important;
}


.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: unset !important;
}


.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: var(--chakra-colors-gray-500) !important;
  font-size: 15.5px !important;
}

.react-multi-email {
  background-color: var(--chakra-colors-gray-50) !important;
  border: solid 1px var(--chakra-colors-gray-300) !important;
  min-height: 120px;
  padding: 12px 16px !important;
  font-family: 'Lexend', sans-serif !important;

  span {
    color: var(--chakra-colors-gray-500) !important;
    font-weight: 400;
    font-size: 15.5px;
  }

  input {
    background-color: transparent;
  }

  [data-tag] {
    background-color: var(--chakra-colors-gray-200) !important;
    border-radius: 16px !important;
    color: var(--chakra-colors-gray-900) !important;
    font-weight: 400 !important;

    div {
      padding: 4px !important;

    }
  }
}

#font-picker {
  flex: 1;
  box-shadow: none;


  button {
    color: var(--chakra-colors-gray-900) !important;
  }

  >button {
    background-color: var(--chakra-colors-gray-50) !important;

    border: solid 1px var(--chakra-colors-gray-300);
    border-radius: 16px;
  }
}

.tox-editor-container {
  margin-top: -20px !important;
}

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background-color: var(--chakra-colors-gray-50) !important;
}

.tox.tox-tinymce-inline .tox-editor-header {
  box-shadow: var(--chakra-shadows-md) !important;
  border: solid 1px var(--chakra-colors-gray-300) !important;
}